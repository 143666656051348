.lipaworld_navbar {
  display: flex;
  /* align-items: center; */
  padding: 1rem 2rem;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 2rem;
}

.lipaworld_logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.lipaworld_logoImage {
  height: 32px;
  width: auto;
}

.lipaworld_logoText {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.lipaworld_searchContainer {
  display: none;
  /* display: flex; */
  flex: 1;
  max-width: 800px;
  background: #f5f5f5;
  border-radius: 8px;
  position: relative;
  opacity: 0;
  cursor: pointer;
}

.lipaworld_categorySelect {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 0.5rem;
  border-right: 1px solid #ddd;
  cursor: pointer;
}

.lipaworld_chevron {
  fill: #666;
}

.lipaworld_searchInput {
  flex: 1;
  padding: 0.75rem 1rem;
  border: none;
  background: transparent;
  font-size: 1rem;
  outline: none;
}

.lipaworld_searchButton {
  padding: 0.75rem 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
}

.lipaworld_searchButton svg {
  fill: #666;
}

.lipaworld_navActions {
  display: flex;
  gap: 1.5rem;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
}

.lipaworld_navButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
  font-size: 0.875rem;
}

.lipaworld_navIcon {
  fill: #666;
  width: 20px;
  height: 20px;
}

.lipaworld_searchIcon {
  display: none;
}

.searchDropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 1000;
  min-width: 100%;
}

.searchContent {
  padding: 24px;
}

.popularSection {
  margin-bottom: 32px;
}

.sectionTitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 16px;
  font-weight: normal;
}

.suggestions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.suggestionItem {
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
}

.recentList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.recentItem {
  padding: 12px 0;
  border-bottom: 1px solid #f5f5f5;
  font-size: 16px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .lipaworld_navbar {
    padding: 1rem;
    gap: 1rem;
  }

  .lipaworld_logoText {
    display: none;
  }

  .lipaworld_navButton span {
    font-size: 0.75rem;
  }
}

@media (max-width: 768px) {
  .lipaworld_navbar {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem;
  }
  .lipaworld_searchContainer {
    display: none;
  }

  .lipaworld_searchIconContainer {
    margin-left: auto;
  }

  .lipaworld_searchIcon {
    display: block !important;
  }

  .searchDropdown {
    display: none;
  }

  .lipaworld_navActions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
    margin: 0;
    gap: 0;
  }

  .lipaworld_navButton {
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem;
    flex: 1;
  }

  .lipaworld_navButton span {
    font-size: 0.70rem;
  }

  body {
    padding-bottom: 70px;
  }
}

@media (max-width: 480px) {
  .lipaworld_categorySelect span {
    display: none;
  }

  .lipaworld_navIcon {
    width: 24px;
    height: 24px;
  }

  .searchDropdown {
    display: none;
  }
}

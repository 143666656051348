.overlay {
  position: fixed;
  inset: 0;
  /* background: rgba(0, 0, 0, 0.3); */
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  z-index: 1000;
}

.modal {
  background-color: white;
  border-radius: 0.75rem;
  width: 100%;
  max-width: 40rem;
}

.modalContent {
  padding: 1rem;
}

.closeButton {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.75rem;
}

.closeButton button {
  padding: 0.25rem;
  border-radius: 50%;
  border: none;
  background: none;
  cursor: pointer;
}

.closeButton button:hover {
  background-color: #f3f4f6;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.imageContainer {
  background-color: #ffffff;
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  margin-bottom: 0.75rem;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.title {
  font-size: 1rem;
  font-weight: 600;
}

.price {
  color: #6d28d9;
  font-weight: 600;
  margin-top: 0.25rem;
}

.accordionContainer {
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.accordionButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  background: none;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.875rem;
}

.accordionContent {
  padding: 0.75rem;
  background-color: #f9fafb;
  border-radius: 0.75rem;
  color: #4b5563;
  font-size: 0.875rem;
}

.section {
  margin-bottom: 1rem;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.sectionHeader h3 {
  font-weight: 600;
  font-size: 0.875rem;
}

.sectionLabel {
  color: #4b5563;
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.amountGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.amountButton {
  padding: 0.25rem 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #4b5563;
  background: none;
  cursor: pointer;
}

.amountButtonActive {
  background-color: #16a34a;
  color: white;
  border-color: #16a34a;
}

.customAmountInput {
  display: flex;
  align-items: center;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.currencySelect {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.5rem;
  border-right: 1px solid #e5e7eb;
}

.currencySelect img {
  width: 1rem;
  height: 1rem;
}

.customAmountInput input {
  flex: 1;
  border: none;
  background: none;
  padding: 0.25rem 0.5rem;
  outline: none;
  font-size: 0.875rem;
}

.quantityControls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantityButton {
  padding: 0.25rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background: none;
  cursor: pointer;
}

.quantityInput {
  flex: 1;
  text-align: center;
  padding: 0.25rem;
  background-color: #f9fafb;
  border: none;
  border-radius: 0.5rem;
  font-size: 0.875rem;
}

.footer {
  padding-top: 0.75rem;
  border-top: 1px solid #e5e7eb;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.totalAmount {
  color: #6d28d9;
  font-weight: 600;
}

.buyNowButton {
  width: 100%;
  padding: 0.5rem;
  background-color: #16a34a;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
}

.addToCartButton {
  width: 100%;
  padding: 0.5rem;
  background: none;
  color: #16a34a;
  border: 1px solid #16a34a;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.875rem;
}

@media (max-width: 480px) {
  .modal {
    max-width: 100%;
    border-radius: 0.5rem;
    height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
  }

  .modalContent {
    padding: 0.75rem;
  }

  .closeButton {
    margin-bottom: 0.5rem;
  }

  .gridContainer {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .imageContainer {
    margin-bottom: 0.5rem;
    padding: 0.75rem;
  }

  .title {
    font-size: 0.875rem;
  }

  .price {
    font-size: 0.875rem;
  }

  .accordionButton {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .accordionContent {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .sectionHeader h3 {
    font-size: 0.75rem;
  }

  .sectionLabel {
    font-size: 0.6875rem;
  }

  .amountGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .amountButton {
    font-size: 0.6875rem;
  }

  .customAmountInput {
    padding: 0.25rem 0.5rem;
  }

  .currencySelect {
    padding: 0 0.5rem;
  }

  .customAmountInput input {
    font-size: 0.75rem;
  }

  .quantityButton {
    padding: 0.25rem;
  }

  .quantityInput {
    font-size: 0.75rem;
  }

  .footer {
    padding-top: 0.5rem;
  }

  .total {
    margin-bottom: 0.5rem;
  }

  .buyNowButton,
  .addToCartButton {
    padding: 0.375rem;
    font-size: 0.75rem;
  }
}

.securityContainer {
    padding: 32px;
    max-width: 1200px;
    margin: 0 auto;
}

.securityTitle {
    font-size: 32px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 24px;
}

.securityCard {
    background: white;
    border-radius: 24px;
    padding: 32px;
    margin-bottom: 24px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}

.container {
    display: flex;
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
    gap: 24px;
}

.h1 {
    color: var(--Text-Dark, #010E1F);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
}

.mainContent {
    flex: 1;
}

.tierSection {
    padding: 24px 0;
    border-bottom: 1px solid #EAECF0;
}

.tierSection:last-child {
    border-bottom: none;
}

.tierHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.tierTitleGroup {
    display: flex;
    align-items: center;
    gap: 8px;
}

.tierTitle {
    font-size: 18px;
    font-weight: 600;
    color: #101828;
    margin: 0;
}

.tierBadge {
    background: #F4EBFF;
    color: #6941C6;
    padding: 2px 8px;
    border-radius: 16px;
    font-size: 14px;
}

.tierBadge2 {
    background: #8D93E8;;
    color:  white;
    padding: 2px 8px; 
    border-radius: 16px;
    font-size: 14px;
}

.tierBadgeDark {
    background: #101828;
    color: white;
    padding: 2px 8px;
    border-radius: 16px;
    font-size: 14px;
}

.tierSubtitle {
    color: #667085;
    font-size: 14px;
    margin: 4px 0 16px 0;
}

.tierList {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #667085;
}

.tierList li {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    font-size: 14px;
}

.tierList li::before {
    content: "•";
    color: #D0D5DD;
}

.tierActions {
    display: flex;
    align-items: center;
    gap: 12px;
}

.unverifiedBadge {
    background: #FEF3F2;
    color: #B42318;
    padding: 2px 8px;
    border-radius: 16px;
    font-size: 14px;
}

.verifyButton {
    background: white;
    border: 1px solid #D0D5DD;
    padding: 8px 16px;
    border-radius: 8px;
    color: #344054;
    font-size: 14px;
    cursor: pointer;
}

.passwordSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-bottom: 24px;
}

.passwordGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.passwordGroup label {
    font-size: 14px;
    color: #344054;
    font-weight: 500;
}

.passwordInput {
    display: flex;
    align-items: center;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding: 10px 14px;
}

.passwordInput input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 16px;
}

.togglePassword {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: #98A2B3;
}

.passwordHint {
    color: #98A2B3;
    font-size: 14px;
}

.saveButton {
    background: #039855;
    color: white;
    border: none;
    padding: 10px 18px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.pinHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pinSubtitle {
    color: #667085;
    font-size: 14px;
    margin: 4px 0 0 0;
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        position: static;
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .securityContainer {
        padding: 16px;
    }

    .securityCard {
        padding: 24px;
    }

    .grid {
        grid-template-columns: 1fr;
    }

    .passwordSection {
        grid-template-columns: 1fr;
    }

    .tierHeader {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .tierActions {
        width: 100%;
        justify-content: space-between;
    }

    .pinHeader {
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
    }

    .verifyButton {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .securityTitle {
        font-size: 24px;
    }

    .cardTitle {
        font-size: 20px;
    }

    .tierTitleGroup {
        flex-direction: column;
        align-items: flex-start;
    }
}
@font-face {
  font-family: "Visby CF";
  src: url("../../public/fonts/VisbyCF-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Visby CF";
  src: url("../../public/fonts/VisbyCF-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Visby CF";
  src: url("../../public/fonts/VisbyCF-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

html {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Visby CF", sans-serif;
  font-weight: 500;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
  margin-top: 5rem;
}

footer {
  flex-shrink: 0;
}

.lipalogo {
  display: flex;
  width: 300px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

/* Hero */
.color {
  color: #00ae5a;
}

.navlinks img {
  margin-left: -30px;
}

.intro {
  margin-top: 30px;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: -0.96px;
}
.top {
  color: var(--Violet-Secondary-violet-secondary-600, #602182);
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px; /* 142.857% */
  border-radius: 40px;
  background: var(--White-white-50, #fefefe);
  box-shadow: 0px 4px 1rem 0px rgba(64, 0, 191, 0.16);
  display: flex;
  padding: 10px 1rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 300px;
}
.tops {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
}

.left-hero {
  width: 40%;
}

.right-hero {
  width: 45%;
}

.floating-converter {
  position: absolute;
  right: 40%;
  top: 750px;
}

.left-hero h2 {
  font-size: 1rem;
}

.customerrightbottom {
  display: flex;
  padding: 11.445px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.861px;
  flex: 1 0 0;
  align-self: stretch;
  margin-left: 1070px;
  margin-top: -112px;
}

.herop {
  color: var(--Text-Dark-Grey, #55667a);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.herocountry {
  width: 252px;
  padding: 12.862px 1rem;
  margin-top: -500px;
  align-items: center;
  gap: 32px;
  border-radius: 10.831px;
  box-shadow: 0px 6.092px 33.847px 0px rgba(0, 153, 80, 0.1);
  margin-left: 750px;
}

.cs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 230px;
  padding: 12.862px 1rem;
  gap: 32px;
  border-radius: 10.831px;
  background: var(--Background-Surface-primary, #fefefe);
  box-shadow: 0px 6.092px 33.847px 0px rgba(0, 153, 80, 0.2);
  margin-top: -555px;
}

.tagwallet {
  color: var(--Violet-Secondary-violet-secondary-600, #602182);
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  border-radius: 40px;
  background: var(--White-white-50, #fefefe);
  box-shadow: 0px 4px 1rem 0px rgba(64, 0, 191, 0.16);
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 140px;
  margin-bottom: 1.2rem;
}

.payments {
  display: flex;
  margin-top: 40px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px 20px 0;
  margin-left: 0;
  gap: 24px;
}

.star {
  display: flex;
  align-items: flex-start;
  gap: 3.304px;
  margin-left: 630px;
  margin-top: -70px;
  flex-direction: column;
}

.starc {
  background: #219653;
  display: flex;
  width: 16.52px;
  height: 16.52px;
  padding: 1.239px;
  justify-content: center;
  align-items: center;
}
.trust {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.652px;
  color: white;
}
.trustp {
  color: var(--White-white-50, #fff);
  width: 36.063px;
  font-size: 8.26px;
  font-style: normal;
  font-weight: 700;
  line-height: 10.738px; /* 130% */
}
.stars {
  display: flex;
  gap: 3.304px;
}

.but {
  display: flex;
}

.buthero {
  border-radius: 8px;
  border: 1px solid var(--Black-black-50, #e7e7e7);
  background: var(--White-white-50, #fff);
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

/* Media query */
@media screen and (max-width: 768px) {
  .tops {
    margin: 0;
    padding: 0 20px;
    flex-direction: column;
  }

  .topsec {
    margin: 0;
    width: 100%;
    text-align: center;
  }

  .buthero {
    display: flex;
    width: 10%;
  }

  .top {
    width: 80%;
    max-width: 300px;
    margin: 0 auto;
    font-size: 12px;
    padding: 8px 12px;
  }

  .intro {
    font-size: 32px;
    line-height: 40px;
    margin-top: 20px;
    margin-bottom: 1rem;
  }

  .herop {
    font-size: 1rem;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;
  }

  .customer {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .customer img {
    width: 100%;
    max-width: 400px;
    height: auto;
  }

  .payments {
    margin: 24px 0;
    padding: 0;
    justify-content: center;
    gap: 1rem;
  }

  .payments img {
    width: 32px;
    height: auto;
  }

  .star {
    margin: 24px auto;
    width: 100%;
    align-items: center;
    gap: 8px;
    margin-top: -50px;
    margin-left: -110px;
  }

  .stars {
    gap: 4px;
  }

  .starc {
    width: 14px;
    height: 14px;
    padding: 1px;
  }

  .customerrightbottom {
    margin: 24px auto;
    position: static;
    width: 100%;
    max-width: 200px;
    margin-top: -60px;
    margin-right: -50px;
  }

  .herocountry {
    width: 100%;
    max-width: 252px;
    margin: 24px auto;
    position: static;
  }

  .cs {
    width: 100%;
    max-width: 200px;
    margin: 24px auto;
    position: static;
  }

  .herocountry,
  .cs {
    display: none;
  }

  .hammob {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 80%;
    background: white;
    transition: right 0.3s ease-in-out;
    z-index: 50;
    padding: 1rem;
  }

  .hammob.active {
    right: 0;
  }

  button {
    margin: 8px;
  }
}

.rec {
  color: var(--Text-Dark-Grey, #55667a);
  font-size: 18px;
  font-style: normal;
  line-height: 28px; /* 155.556% */
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}
.recognition {
  display: flex;
  padding: 40px 60px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.reclogo {
  display: flex;
}

/* Features */

.features-section {
  padding: 70px 60px;
}

.features-title {
  width: 100%;
  color: var(--Text-Dark, #010e1f);
  text-align: center;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.features-grid {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-left: -120px;
  margin-right: -120px;
}

.feature-card {
  padding: 24px;
  text-align: center;
}

.feature-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62.5px;
  height: 62.5px;
  margin: 0 auto 24px;
  border-radius: 62.5px;
  background-color: #fff;
  border: 0.63px solid #e2e2e2;
}

.feature-icon img {
  width: 30px;
  height: 30px;
}

.feature-title {
  color: #010e1f;
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.feature-description {
  color: #55667a;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
}

/* Tablet */
@media (min-width: 640px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    padding: 32px;
  }
}

.purp {
  border-radius: 22px;
}
.subsec1 {
  margin-top: 110px;
  margin-left: 50px;
}
.subsec2 {
  margin-top: 80px;
}
.getimg {
  width: 608px;
  height: 422px;
}

/* Testimonial */
.test {
  padding-top: 80px;
  padding-bottom: 80px;
}

.testimonials-container {
  padding: 30px 30px;
}

.title-sectiontest {
  text-align: center;
  margin-bottom: 50px;
}

.titletest {
  font-size: 48px;
  font-weight: 700;
  color: #161618;
  letter-spacing: -0.96px;
}

.subtitletest {
  font-size: 1rem;
  color: #55667a;
}

.reviews-wrapper {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 50px;
}

.review-card {
  background-color: #fff;
  padding: 24px;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  width: 90%;
  border: 1px solid #e2e2e2;
}

.review-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  margin-left: 145px;
}

.review-text {
  font-size: 1rem;
  color: #010e1f;
  margin-bottom: 10px;
}

.reviewer-name {
  font-size: 14px;
  font-weight: 600;
  color: #55667a;
}

.trustpilot-section {
  text-align: center;
}

.trustpilot-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.rating {
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.score {
  font-size: 12px;
  font-family: "Vampiro One", sans-serif;
  color: #000;
}

.trustpilot-logo {
  display: flex;
  align-items: center;
  gap: 5px;
}

.logo-imagetest {
  width: 14px;
  height: 13.25px;
}

.trustpilot-button {
  padding: 10px 20px;
  border: 1px solid #8ae2b7;
  border-radius: 8px;
  background-color: #fff;
  color: #008846;
  font-weight: 600;
  cursor: pointer;
}

.trustpilot-button:hover {
  background-color: #e8f5e9;
}

/* FAQ */
#faqs {
  padding: 80px 60px;
  display: flex;
}

.faq-content {
  flex: 3;
  margin-right: 80px;
}

.faq-title {
  color: var(--Text-Primary, #161618);
  align-self: stretch;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.96px;
  margin-bottom: 30px;
  margin-top: 25px;
}

.faq-subtitle {
  color: var(--Text-Dark-Grey, #55667a);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.faq-tag {
  color: var(--Violet-Secondary-violet-secondary-600, #602182);
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  border-radius: 40px;
  background: var(--White-white-50, #fefefe);
  box-shadow: 0px 4px 1rem 0px rgba(64, 0, 191, 0.16);
  display: flex;
  padding: 10px 1rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 200px;
}

.faq-questions-container {
  flex: 4;
  overflow-y: auto;
  max-height: 600px;
}

.faq-questions {
  padding-right: 1rem;
}

.faq-question {
  border: 1px solid #e6e7e8;
  border-radius: 1rem;
  margin-bottom: 24px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq-question-summary {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.faq-question-details {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 0 0 1rem 1rem;
  margin-top: -24px;
  margin-bottom: 24px;
  border: 1px solid #e6e7e8;
  border-top: none;
}

/* howitworks */
.how-section {
  background-color: #011a38;
  color: #fefefe;
  padding: 60px 40px;
  border-radius: 42px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* margin-left: -75px;
  margin-right: -75px; */
  margin-bottom: 40px;
  margin-top: 60px;
}

.headerhow {
  text-align: center;
  margin-bottom: 40px;
}

.headerhow h2 {
  font-size: 48px;
  font-weight: 700;
}

.headerhow p {
  font-size: 1rem;
  color: #b0b8c1;
}

.contenthow {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.left-sidehow {
  width: 35%;
}

.left-sidehow ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.step-itemhow {
  background-color: #041e3c;
  padding: 24px 32px;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.step-iconhow {
  width: 32px;
  height: 32px;
}

.right-sidehow {
  width: 60%;
  background-color: #011226;
  border-radius: 24px;
  padding: 40px 0 0 40px;
  display: flex;
  justify-content: space-between;
}

.text-contenthow {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.globe-iconhow {
  width: 48px;
  height: 48px;
  background-color: #012755;
  border-radius: 50%;
  padding: 1rem;
}

.mobile-imagehow {
  width: 300px;
  border-radius: 1rem;
}

/* How Queries */
/* Tablet Devices */
@media screen and (max-width: 1024px) {
  .how-section {
    padding: 40px 20px;
    margin-left: -30px;
    margin-right: -30px;
    border-radius: 32px;
  }

  .headerhow h2 {
    font-size: 40px;
  }

  .contenthow {
    flex-direction: column;
    gap: 30px;
  }

  .left-sidehow {
    width: 100%;
  }

  .right-sidehow {
    width: 100%;
    padding: 30px;
  }

  .mobile-imagehow {
    width: 250px;
  }
}

/* Mobile Devices */
@media screen and (max-width: 768px) {
  .how-section {
    padding: 30px 15px;
    margin-left: -2px;
    margin-right: -2px;
    border-radius: 24px;
    gap: 24px;
  }

  .headerhow {
    margin-bottom: 24px;
  }

  .headerhow h2 {
    font-size: 32px;
  }

  .headerhow p {
    font-size: 14px;
  }

  .step-itemhow {
    padding: 1rem 20px;
    gap: 12px;
  }

  .step-iconhow {
    width: 24px;
    height: 24px;
  }

  .right-sidehow {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
  }

  .text-contenthow {
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .globe-iconhow {
    width: 40px;
    height: 40px;
    padding: 12px;
  }

  .mobile-imagehow {
    width: 80%;
    max-width: 300px;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 480px) {
  main {
    margin-top: 2.7rem;
  }
  .how-section {
    padding: 24px 12px;
    gap: 20px;
  }

  .left-hero {
    width: 100%;
  }

  .intro {
    text-align: center;
  }

  .right-hero {
    width: 100%;
  }

  .floating-converter {
    display: none;
  }

  .headerhow h2 {
    font-size: 28px;
  }

  .step-itemhow {
    padding: 12px 1rem;
    font-size: 14px;
  }

  .right-sidehow {
    padding: 20px;
  }

  .mobile-imagehow {
    width: 90%;
  }

  .font-medium {
    font-weight: 600;
  }
}

/* partners */

.partners-section {
  padding: 70px 60px;
  text-align: center;
}

.partners-header h1 {
  font-size: 38px;
  font-weight: 900;
  letter-spacing: -0.96px;
  color: #010e1f;
  line-height: 74.88px;
  margin-bottom: 24px;
  width: 50%;
  margin-left: 350px;
  font-style: bold;
}

.partners-header p {
  font-size: 20px;
  font-weight: 600;
  color: #55667a;
  line-height: 24px;
  /* max-width: 730px; */
  margin: 0 auto;
}

.partners-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 60px;
}

.partner-logo {
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 1rem;
  padding: 1rem 24px;
  box-shadow: 0 0 30px rgba(0, 191, 99, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 108px;
  flex-basis: calc(20% - 20px);
  max-width: calc(20% - 20px);
}

.partner-logo img {
  max-width: 100%;
  max-height: 60px;
  object-fit: contain;
}
.part {
  text-align: center;
  margin-top: -30px;
  color: var(--Text-Dark-Grey, #55667a);
  font-size: 15.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  width: 70%;
  align-items: center;
  margin-left: 150px;
}
.parthead {
  align-self: stretch;
  color: var(--Text-Dark, #010e1f);
  text-align: center;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
}
.shadow {
  box-shadow: 0px 0px 30.1px 0px rgba(0, 191, 99, 0.4);
  border-radius: 9px;
}
/* Partner Queries */
/* Tablet Devices */
@media screen and (max-width: 768px) {
  .partners-section {
    padding: 40px 20px;
  }

  .partners-header h1 {
    font-size: 42px;
    line-height: 52px;
  }

  .partner-logo {
    flex-basis: calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
  }

  .part {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
  }

  .parthead {
    font-size: 42px;
  }
}

/* Mobile Devices */
@media screen and (max-width: 480px) {
  .partners-section {
    padding: 30px 15px;
  }

  .partners-header h1 {
    font-size: 30px;
    /* line-height: 40px; */
    margin-bottom: 1rem;
    margin-left: 90px;
    width: 65%;
  }

  .partners-header p {
    font-size: 20px;
    line-height: 22px;
    width: 100%;
  }

  .partners-logos {
    gap: 15px;
    margin-top: 40px;
    width: 65%;
    margin-left: 95px;
  }

  .partner-logo {
    flex-basis: calc(50% - 15px);
    max-width: calc(50% - 15px);
    height: 90px;
    padding: 12px 1rem;
  }

  .part {
    width: 100%;
    margin-top: -20px;
    font-size: 13px;
  }

  .parthead {
    font-size: 32px;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 320px) {
  .partner-logo {
    flex-basis: 100%;
    max-width: 100%;
  }

  .partners-header h1 {
    font-size: 5px;
    line-height: 36px;
  }
}

/* How section */

.how {
  border-radius: 42px;
  background: var(--Dark-Blue-dark-blue-500, #011a38);
  width: 100%;
  display: flex;
  padding: 60px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  height: 70vh;
  margin-left: -90px;
  margin-right: -90px;
}
.howsec {
  display: flex;
  height: 472px;
  padding: 0px 60px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 42px;
  background: var(--Dark-Blue-dark-blue-500, #011a38);
  margin-left: -90px;
  margin-right: -90px;
}
.works {
  color: var(--Outline-Outline-Grey, #b0b8c1);
}
.purple {
  border-radius: 42px;
  background: linear-gradient(90deg, #2d0f3c 0%, #7a29a2 100%);
  display: flex;
  height: 472px;
  padding: 0px 60px;
  justify-content: space-between;
  align-items: center;
  /* flex: 1 0 0; */
}

.black {
  border-radius: 42px 42px 0px 0px;
  background: linear-gradient(180deg, #000b18 0%, #000a05 100%);
  display: flex;
  padding: 64px 60px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.partlogo {
  margin-top: 30px;
}

.h3visit {
  color: var(--Text-Text-white, #fefefe);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}
.sec1 {
  width: 33.3%;
}
.sec2 {
  width: 66.6%;
  display: flex;
  border-radius: 24px;
  margin-top: -80px;
}

/* Send */
.sendsec {
  padding-top: 100px;
  padding-bottom: 100px;
}

.send {
  color: var(--Text-Primary, #161618);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
}
.sendp {
  color: var(--Text-Dark-Grey, #55667a);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

@media screen and (max-width: 768px) {
  section {
    padding: 24px 1rem;
  }

  .containerm {
    padding: 0;
  }

  /* Reverse flex order to match screenshot */
  .flex-colm {
    flex-direction: column-reverse !important;
  }

  .send {
    font-size: 32px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 1rem;
    letter-spacing: -0.5px;
  }

  .sendp {
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 24px;
    padding: 0 8px;
  }

  button {
    display: block;
    margin: 0 auto;
    font-size: 1rem;
  }

  .sendmob {
    width: 100%;
  }
  /* Image styles */
  .purp {
    margin-left: -10px;
    border-radius: 24px;
    margin-top: 32px;
  }
  /* Content spacing */
  .plmob {
    padding: 0;
    margin-bottom: 24px;
  }

  .mb4mob {
    margin-bottom: 0;
  }
}

/* More */

.morepic {
  width: 806px;
  height: 663px;
  flex-shrink: 0;
  /* margin-top: 113px; */
}
.moresec {
  display: flex;
  height: 663px;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 42px;
  background: linear-gradient(92deg, #00502a 2.96%, #008244 107.73%);
}
.moreheader {
  color: var(--Text-White, #fefefe);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  align-self: stretch;
}
.morep {
  color: var(--Text-White, #fefefe);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  align-self: stretch;
}

/* Mobile More */
@media screen and (max-width: 768px) {
  .moresec {
    margin: 0;
    padding: 32px 24px;
    height: auto;
    flex-direction: column;
    border-radius: 24px;
    text-align: center;
  }

  .morespace {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-bottom: 32px;
  }

  .moreheader {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }

  .morep {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 24px;
    padding: 0 1rem;
  }

  .morebutton {
    width: auto;
    min-width: 140px;
    padding: 12px 24px;
    font-size: 1rem;
  }

  .morepic {
    width: 100%;
    height: auto;
    margin-top: 24px;
    border-radius: 1rem;
    object-fit: cover;
  }
}
.morebutton,
.getbutton {
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Green-green-200, #8ae2b7);
  background: var(--White-white-50, #fff);
}

/*Get section */

.getsec {
  display: flex;
  height: 472px;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 42px;
  background: linear-gradient(90deg, #2d0f3c 0%, #7a29a2 100%);
}
.getheader {
  color: var(--Text-White, #fefefe);
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  align-self: stretch;
}
.getp {
  color: var(--Text-White, #fefefe);
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  align-self: stretch;
}
/* Tablet Devices */
@media screen and (max-width: 1024px) {
  .getsec {
    padding: 40px 30px;
    margin-left: -40px;
    margin-right: -40px;
    height: auto;
    flex-direction: column;
    text-align: center;
  }

  .getheader {
    font-size: 42px;
    margin-bottom: 20px;
  }

  .getp {
    font-size: 17px;
    margin-bottom: 30px;
  }

  .getbutton {
    margin: 0 auto 30px;
  }

  .getimg {
    max-width: 80%;
    margin: 0 auto;
  }
}

/* Mobile Devices */
@media screen and (max-width: 768px) {
  .getsec {
    padding: 30px 20px;
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 24px;
  }

  .getheader {
    font-size: 36px;
    letter-spacing: -0.5px;
    margin-bottom: 1rem;
  }

  .getp {
    font-size: 1rem;
    line-height: 22px;
    margin-bottom: 24px;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 480px) {
  .getsec {
    padding: 24px 15px;
    margin-left: -2px;
    margin-right: -2px;
    border-radius: 20px;
  }

  .getheader {
    font-size: 32px;
    letter-spacing: -0.3px;
  }

  .getp {
    font-size: 15px;
    line-height: 20px;
  }

  .getimg {
    max-width: 90%;
  }

  .text-center-mobile {
    text-align: center;
  }
}

/* Footer */
.footersec1 {
  display: flex;
  padding: 40px 0px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.footersec2 {
  display: flex;
  padding: 40px 0px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.sec2p {
  color: var(--Text-Light-Grey, #8a96a3);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  width: 290px;
}
.joinh3 {
  color: var(--Text-White, #fefefe);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  width: 290px;
}
.howp {
  color: var(--Outline-Outline-Grey, #b0b8c1);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  align-self: stretch;
  width: 70%;
}
.shift {
  margin-left: 730px;
}

@media screen and (max-width: 768px) {
  .footersec1,
  .footersec2 {
    flex-direction: column;
    padding: 20px 1rem;
    gap: 24px;
  }

  .sec2p {
    width: 100%;
    font-size: 1rem;
    line-height: 24px;
  }

  .joinh3 {
    width: 100%;
    font-size: 20px;
    line-height: 28px;
  }

  .howp {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }

  .shift {
    margin-left: 0;
  }

  /* Add padding to container to prevent content from touching edges */
  .container {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 480px) {
  .foot {
    margin-left: 13px;
    margin-right: 13px;
  }

  .footersec1,
  .footersec2 {
    padding: 1rem 12px;
  }
}

/* About Us */
.top2 {
  color: var(--Violet-Secondary-violet-secondary-600, #602182);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  border-radius: 40px;
  background: var(--White-white-50, #fefefe);
  box-shadow: 0px 4px 1rem 0px rgba(64, 0, 191, 0.16);
  display: flex;
  padding: 10px 1rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 15%;
  margin-left: 500px;
  margin-top: 40px;
}
.storyheader {
  display: flex;
  width: 796px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  margin-left: 200px;
}
.storyh2 {
  color: var(--Text-Dark, #010e1f);
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  align-self: stretch;
  margin-top: -15px;
}
.storyp {
  color: var(--Text-Dark-Grey, #55667a);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  align-self: stretch;
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.ceoimg {
  border-radius: 30px;
}

/* financial inclusion */
.fidiv {
  margin-top: 40px;
  margin-left: 50px;
}
.fip {
  color: var(--Text-Dark-Grey, #55667a);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.statsec {
  display: flex;
  padding: 80px 60px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  background: var(--Green-green-400, #33cc82);
  margin-left: -110px;
  margin-right: -110px;
}
.statext {
  color: var(--Violet-Secondary-violet-secondary-700, #4b1a66);
  justify-content: space-around;
  align-items: center;
  align-self: stretch;
}
.tsec {
  display: flex;
  padding: 80px 60px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  align-self: stretch;
  background: var(--Dark-Blue-dark-blue-900, #000b18);
  margin-left: -110px;
  margin-right: -110px;
}
.tailoredheader {
  display: flex;
  width: 520px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  justify-content: center;
}
.tailoredh2 {
  color: var(--Text-White, #fefefe);
  text-align: center;
  align-self: stretch;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
}
.tp {
  color: var(--Text-Light-Grey, #8a96a3);
  text-align: center;
  align-self: stretch;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.world {
  width: 1338px;
  height: 443px;
}
.exh2 {
  color: var(--Text-Primary, #161618);
  align-self: stretch;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  width: 50%;
}
.exp {
  color: var(--Text-Dark-Grey, #55667a);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  width: 50%;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .statsec,
  .tsec {
    margin-left: -60px;
    margin-right: -60px;
    padding: 60px 40px;
  }

  .world {
    width: 100%;
    height: auto;
    max-width: 800px;
  }

  .tailoredheader {
    width: 100%;
    max-width: 480px;
  }

  .tailoredh2 {
    font-size: 40px;
    letter-spacing: -0.8px;
  }
}

@media screen and (max-width: 768px) {
  .statsec,
  .tsec {
    margin-left: -30px;
    margin-right: -30px;
    padding: 40px 20px;
    gap: 30px;
  }

  .statext {
    flex-direction: column;
    gap: 30px;
    text-align: center;
  }

  .tailoredh2 {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  .exh2,
  .exp {
    width: 100%;
  }

  .exh2 {
    font-size: 28px;
    letter-spacing: -0.56px;
  }
}

@media screen and (max-width: 640px) {
  .statsec,
  .tsec {
    margin-left: -20px;
    margin-right: -20px;
    padding: 30px 15px;
  }

  .tailoredh2 {
    font-size: 28px;
    letter-spacing: -0.56px;
  }

  .tp {
    font-size: 11px;
    line-height: 20px;
  }

  /* Force slider view on mobile */
  [data-slider-view="false"] {
    display: none;
  }

  .slick-slider {
    margin: 0 -15px;
  }

  .slick-slide {
    padding: 0 15px;
  }

  /* Adjust arrow positions for mobile */
  .slick-prev {
    left: -5px;
  }

  .slick-next {
    right: -5px;
  }
}

/* Additional slider-specific styles */
@media screen and (max-width: 480px) {
  .slick-slide > div {
    margin: 0 10px;
  }

  .TeamMember {
    width: calc(100vw - 60px);
  }
}

/* Contact us */
.get-section {
  background-color: #2d0f3c;
  padding: 40px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -110px;
  margin-right: -110px;
}

.get-container {
  text-align: center;
  max-width: 796px;
}

.get-title {
  font-weight: 700;
  font-size: 48px;
  letter-spacing: -0.96px;
  color: #9b6cb4;
  margin: 0 0 24px;
  line-height: 74.88px;
}

.get-description {
  font-weight: 500;
  font-size: 1rem;
  color: #fefefe;
  line-height: 24px;
}

/* Contact */
.contact-section {
  display: flex;
  gap: 40px;
  padding: 40px 60px;
  /* background-color: #fff; */
}

.contact-form {
  flex: 3;
  padding: 32px;
  /* background: #fff; */
  border-radius: 24px;
  box-shadow: 0px 4px 24px 0px rgba(2, 142, 75, 0.1);
}

.form-title {
  font-size: 24px;
  font-weight: 600;
  color: #0c0c0c;
}

.form-subtitle {
  font-size: 14px;
  color: #55667a;
  margin-top: 8px;
}

.tabs {
  display: flex;
  margin-top: 32px;
  gap: 8px;
}

.tab {
  flex: 1;
  padding: 8px 1rem;
  border-bottom: 1px solid #e2e2e2;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
}

.tab.active {
  border-color: #00ae5a;
  color: #00ae5a;
}

.form {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}
.form-groupname {
  display: flex;
}

.form-group label,
.form-groupname label {
  font-size: 12px;
  font-weight: 600;
  color: #011a38;
  margin-bottom: 4px;
}

.form-group input,
.form-group textarea,
.form-groupname input,
.form-groupname textarea {
  padding: 12px 14px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
}

textarea {
  resize: none;
  height: 112px;
}

.submit-button {
  padding: 12px 1rem;
  background-color: #00ae5a;
  color: #fff;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  border: none;
}

.contact-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 35px;
}

.contact-info h2 {
  font-size: 24px;
  font-weight: 600;
  color: #0c0c0c;
}

.contact-info p {
  font-size: 14px;
  font-weight: 500;
  color: #55667a;
}

.contact-info a {
  color: #008846;
  font-weight: 500;
}

.social-media {
  display: flex;
  gap: 12px;
  margin-top: 8px;
}

.bizh2 {
  margin-bottom: 30px;
}
@media screen and (max-width: 480px) {
  .header-section {
    padding: 32px 1rem;
  }

  .header-section h1 {
    font-size: 28px;
  }

  .header-section p {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .contact-section {
    padding: 32px 40px;
    gap: 32px;
  }
}

@media screen and (max-width: 768px) {
  .contact-section {
    flex-direction: column;
    padding: 24px 20px;
  }

  .contact-form {
    flex: none;
    padding: 24px;
    border-radius: 1rem;
  }

  .contact-info {
    margin-top: 24px;
    gap: 24px;
  }

  .form-title {
    font-size: 20px;
  }

  .contact-info h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .get-section {
    margin-left: 8px;
    margin-right: 8px;
  }

  .get-title {
    font-size: 25px;
  }

  .get-description {
    font-size: 12px;
  }
  .contact-section {
    padding: 1rem;
  }

  .contact-form {
    padding: 1rem;
    border-radius: 12px;
  }

  .form-groupname {
    flex-direction: column;
    gap: 1rem;
  }

  .tabs {
    margin-top: 24px;
    display: flex;
    padding: 8px 1rem;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 12px;
    flex: 1 0 0;
    flex-wrap: wrap;
  }

  .tab {
    padding: 8px 12px;
    font-size: 13px;
    width: 3%;
  }

  .form {
    gap: 20px;
  }

  .form-group input,
  .form-group textarea,
  .form-groupname input {
    padding: 14px;
    font-size: 14px;
  }

  textarea {
    height: 120px;
  }

  .submit-button {
    padding: 1rem;
    font-size: 14px;
  }
}

/* partners */

.partner-hero-container {
  display: flex;
  gap: 90px;
  padding: 80px 60px;
  background-color: #fefefe;
  margin-bottom: -150px;
}

.partner-hero-text {
  max-width: 551px;
}

.shiftpartner {
  margin-left: -70px;
}

.imgpartshift {
  margin-left: 100px;
}

.partner-tag {
  font-family: "Visby CF", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #602182;
  background-color: #fefefe;
  border-radius: 40px;
  padding: 10px 1rem;
  box-shadow: 0 4px 1rem rgba(64, 0, 191, 0.16);
  display: inline-block;
}

.partnerh1 {
  font-family: "Visby CF", sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 74.88px;
  color: #010e1f;
  letter-spacing: -0.96px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.partnerp {
  font-family: "Visby CF", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #55667a;
  margin-bottom: 48px;
}

.partnerbuttons {
  display: flex;
  gap: 12px;
}

.contact-us-button,
.learn-more-button {
  font-family: "Visby CF", sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 1rem;
  border-radius: 8px;
  border: 1px solid;
  cursor: pointer;
}

.contact-us-button {
  background-color: #00ae5a;
  color: #fff;
  border-color: #00bf63;
}

.learn-more-button {
  background-color: #fff;
  color: #010e1f;
  border-color: #e7e7e7;
}

.partner-hero-images {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 621px;
}

.partner-hero-images img {
  width: calc(50% - 1rem);
  border-radius: 1rem;
  object-fit: cover;
  box-shadow: 0 4px 1rem rgba(0, 0, 0, 0.1);
}

.partners-section {
  padding: 70px 60px;
  text-align: center;
  /* margin-left: -110px;
  margin-right: -110px; */
}

.partners-header {
  font-family: "Visby CF", sans-serif;
  font-weight: 700;
  font-size: 48px;
  color: #010e1f;
  line-height: 74.88px;
  margin-bottom: 48px;
}

.partners-grid {
  display: flex;
  justify-content: space-around;
  gap: 24px;
}

.partner-card {
  flex: 1;
  max-width: 300px;
  text-align: center;
}

.partner-icon {
  width: 62.5px;
  height: 62.5px;
  margin: 0 auto 24px;
  border-radius: 62.5px;
  background-color: #fff;
  border: 0.63px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-icon img {
  width: 30px;
  height: 30px;
}

.partner-title {
  font-weight: 600;
  font-size: 23px;
  color: #010e1f;
  margin-bottom: 1rem;
}

.partner-description {
  font-weight: 500;
  font-size: 1rem;
  color: #55667a;
  line-height: 24px;
}
/* .shiftpartner {
  margin-left: -85px;
} */

/* ROI */
.roi-section {
  background-color: #fefefe;
  padding: 80px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.roi-container {
  display: flex;
  gap: 40px;
}

.roi-image-wrapper {
  position: relative;
  width: 950px;
  height: 51rem;
  border-radius: 32px;
  overflow: hidden;
}

.roi-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.roi-card {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  border-radius: 24px;
  padding: 20px;
  width: 142.5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.roi-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.roi-annual {
  color: #8a96a3;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: left;
  margin-left: -60px;
}

.roi-percent {
  color: #010e1f;
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 14px;
}

.roi-graph {
  width: 100%;
  height: auto;
}

.roi-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin-left: 60px;
}

.roi-heading {
  font-size: 48px;
  font-weight: 700;
  color: #161618;
  margin-bottom: 24px;
}

.roi-description {
  font-size: 1rem;
  font-weight: 500;
  color: #55667a;
  margin-bottom: 24px;
}

.roi-button {
  padding: 1rem 24px;
  background-color: #00ae5a;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 30%;
}

.roi-button:hover {
  background-color: #009f50;
}

.grow-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
  height: 686px;
  background: linear-gradient(
    92.31deg,
    rgba(45, 15, 60, 1) 2.96%,
    rgba(122, 41, 162, 1) 107.73%
  );
  border-radius: 42px;
  margin-left: -80px;
  margin-right: -80px;
}

.growcontent {
  max-width: 50%;
  color: #fefefe;
}

.growtitle {
  font-weight: 700;
  font-size: 48px;
  letter-spacing: -0.96px;
  line-height: 74.88px;
}

.growdescription {
  font-family: "Visby CF", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  margin-top: 24px;
}

.growcontact-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-top: 48px;
  background-color: #fff;
  color: #008846;
  font-family: "Visby CF", sans-serif;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #8ae2b7;
}

.growimage-container {
  max-width: 603px;
  border-radius: 32px;
  overflow: hidden;
}

.growimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 32px;
}

.power-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 60px;
  background-color: #fefefe;
}

.power-image {
  width: 603px;
  height: 51rem;
  border-radius: 32px;
  overflow: hidden;
  margin-left: -100px;
}

.power-image-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 32px;
}

.power-content {
  max-width: 600px;
  margin-left: -100px;
  margin-right: -80px;
}

.power-title {
  font-family: "Visby CF", sans-serif;
  font-weight: 700;
  font-size: 48px;
  letter-spacing: -0.96px;
  line-height: 74.88px;
  color: #161618;
  margin-bottom: 24px;
}

.power-description {
  font-family: "Visby CF", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: #55667a;
  margin-bottom: 48px;
}

.power-button {
  padding: 1rem 32px;
  background-color: #00ae5a;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  border: 1px solid #00bf63;
  transition: background-color 0.3s ease;
}

.power-button:hover {
  background-color: #009f50;
}

.explorebody {
  margin: 0;
  background-color: #000b18;
  color: #fefefe;
}

.explore-section {
  padding: 70px 60px;
  text-align: center;
  margin-left: -100px;
  margin-right: -100px;
}

.explore-header h1 {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.96px;
  line-height: 74.88px;
}

.explore-header p {
  font-size: 1rem;
  font-weight: 500;
  color: #8a96a3;
  line-height: 24px;
  max-width: 730px;
  margin: 0 auto;
  margin-top: 24px;
}

.explorecard-container {
  position: relative;
  width: 100%;
  margin-top: 70px;
  overflow: hidden;
}

.explorecard-scroll {
  display: flex;
  gap: 24px;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 20px 0;
  width: calc(100% + 100px);
}

.explorecard-scroll::-webkit-scrollbar {
  display: none;
}

.explorecard {
  background-color: #011228;
  border: 1px solid #011a38;
  border-radius: 1rem;
  width: calc((100% - 72px) / 4);
  min-width: calc((100% - 72px) / 4);
  padding: 80px 1rem 1rem;
  text-align: center;
  transition: transform 0.2s;
  flex-shrink: 0;
}

.explorecard:hover {
  transform: scale(1.05);
}

.exploreicon-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #011a38;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  margin: 0 auto 24px;
}

.exploreicon-wrapper img {
  width: 48px;
  height: 48px;
}

.explorecard h3 {
  font-size: 18px;
  font-weight: 600;
  color: #fefefe;
  line-height: 28px;
  margin-bottom: 8px;
}

.explorecard p {
  font-size: 14px;
  font-weight: 500;
  color: #8a96a3;
  line-height: 20px;
}

.descriptionp {
  color: var(--Text-Light-Grey, #8a96a3);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.partners-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 472px;
  background: linear-gradient(
    90deg,
    rgba(0, 74, 38, 1) 0%,
    rgba(0, 136, 70, 1) 100%
  );
  border-radius: 42px;
  padding: 0 60px;
  margin-left: -80px;
  margin-right: -80px;
}

.partners-content {
  text-align: center;
  color: #fefefe;
}

.partners-title {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.96px;
}

.partners-description {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin: 24px 0;
}

.partners-button {
  padding: 12px 32px;
  background-color: #fff;
  color: #008846;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #8ae2b7;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.partners-button:hover {
  background-color: #e8f5e9;
}

/* Blog */

.stories-section {
  padding: 40px 60px;
  background-color: #00502a;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: -100px;
  margin-right: -100px;
}

@media screen and (max-width: 480px) {
  stories-section {
    margin-left: -2px;
    margin-right: -2px;
  }
  .stories-title {
    font-size: 2px;
  }
  .stories-description {
    font-size: 2px;
    width: 65%;
  }

  .fidiv {
    margin-top: 40px;
    margin-left: 0;
  }
}

.stories-container {
  max-width: 796px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.stories-title {
  font-weight: 700;
  font-size: 48px;
  letter-spacing: -0.96px;
  line-height: 74.88px;
  color: #33cc82;
}

.stories-description {
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: #fefefe;
}

.fostering-container {
  display: flex;
  gap: 40px;
  padding: 40px 60px;
  /* background-color: #ffffff; */
}

.image-section {
  flex: 0 0 603px;
  border-radius: 32px;
  overflow: hidden;
  margin-left: -98px;
}

.community-image {
  width: 100%;
  height: auto;
  border-radius: 32px;
}

.content-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 60px;
  margin-left: 50px;
}

.metadata {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8a96a3;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.separator {
  font-size: 14px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #161618;
}

.description {
  font-size: 1rem;
  font-weight: 500;
  color: #55667a;
  line-height: 24px;
}

.author-section {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.author-name {
  font-size: 1rem;
  font-weight: 500;
  color: #010e1f;
}

.read-more-button {
  font-size: 14px;
  font-weight: 600;
  color: #00ae5a;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.blogs-section {
  padding: 40px 60px;
  margin-left: -100px;
  margin-right: -100px;
}

.blogh1 {
  font-size: 24px;
  font-weight: 700;
  color: #161618;
  margin-bottom: 40px;
}

.blog-card {
  width: calc(33.333% - 27px); /* 40px gap, distributed among 3 cards */
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blogs-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: flex-start; /* Changed from space-between */
}

.blog-image {
  width: 100%;
  height: auto;
}

.blog-content {
  padding: 1rem;
}

.metadata {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8a96a3;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: #161618;
  margin: 1rem 0;
}

.description {
  font-size: 14px;
  font-weight: 500;
  color: #55667a;
  margin-bottom: 1rem;
}

.author-section {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.author-name {
  font-size: 1rem;
  font-weight: 500;
  color: #010e1f;
}

.read-more-button {
  font-size: 14px;
  font-weight: 600;
  color: #00ae5a;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #8a96a3;
  margin: 0 4px;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .blogs-section {
    padding: 32px 40px;
    margin-left: -60px;
    margin-right: -60px;
  }

  .blog-card {
    flex: 1 0 45%;
  }
}

@media screen and (max-width: 768px) {
  .blogs-section {
    padding: 24px 20px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .blogs-grid {
    gap: 24px;
  }

  .blog-card {
    flex: 1 0 100%;
  }

  .blogh1 {
    font-size: 20px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 480px) {
  .blogs-section {
    padding: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .blogs-grid {
    gap: 1rem;
  }

  .blog-content {
    padding: 12px;
  }

  .title {
    font-size: 18px;
    margin: 12px 0;
  }

  .metadata {
    font-size: 12px;
  }

  .description {
    font-size: 13px;
  }

  .author-name {
    font-size: 14px;
  }
}

/* 2 */

.foster-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 240px;
}

.button-main2 {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.arrow-left2 {
  width: 24px;
  height: 24px;
}

.back-text2 {
  color: #010e1f;
}

.image-section2 {
  width: 100%;
  height: 400px;
  border-radius: 32px;
  align-self: stretch;
  overflow: hidden;
}

.community-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.content-section2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.metadata2 {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8a96a3;
  font-size: 0.8rem;
  font-weight: 600;
}

.title2 {
  font-size: 24px;
  font-weight: 600;
  color: #161618;
}

.description2 {
  font-size: 1rem;
  font-weight: 500;
  color: #55667a;
  line-height: 24px;
}

.author-section2 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.author-name2 {
  font-size: 1rem;
  font-weight: 500;
  color: #010e1f;
}

.dot2 {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #8a96a3;
}

.related-section {
  padding: 40px 60px;
}

.related-title {
  font-size: 24px;
  font-weight: 700;
  color: #161618;
  margin-bottom: 40px;
}

.article-container {
  display: flex;
  gap: 40px;
}

.article-card {
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
}

.article-image {
  width: 100%;
  height: auto;
}

.article-content {
  padding: 1rem;
}

.metadata {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8a96a3;
  font-size: 14px;
  font-weight: 600;
}

.article-title {
  font-size: 20px;
  font-weight: 600;
  color: #161618;
  margin: 1rem 0;
}

.article-description {
  font-size: 14px;
  font-weight: 500;
  color: #55667a;
  margin-bottom: 1rem;
}

.author-section {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.author-name {
  font-size: 1rem;
  font-weight: 500;
  color: #010e1f;
}

.read-more-button {
  font-size: 14px;
  font-weight: 600;
  color: #00ae5a;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #8a96a3;
  border-radius: 50%;
}

/* shop */

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.filter-icon {
  padding: 11px;
  border-radius: 8px;
  border: 1px solid #e2e2e2ff;
  margin-left: -120px;
}

.search-bar {
  display: flex;
  height: 44px;
  padding: 10px 14px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid var(--White-white-600, #e2e2e2);
  background: var(--White-white-500, #f8f8f8);
}

.category-dropdown {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #8a96a3ff;
  font-family: "Visby CF", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.divider {
  height: 24px;
  background-color: #e2e2e2ff;
}

.search-input {
  display: flex;
  width: 646px;
  height: 44px;
  padding: 10px 14px;
  align-items: center;
  gap: 1rem;
  border-radius: 8px;
  color: #8a96a3ff;
}

.si {
  margin-left: 390px;
}

.language-switcherN {
  display: flex;
  height: 44px;
  padding: 10px 14px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--White-white-600, #e2e2e2);
  background: var(--White-white-50, #fff);
  /* width: 120px; */
}

.country-flag {
  display: flex;
  align-items: center;
  gap: 4px;
}

.shopping-cart {
  padding: 11px;
  border-radius: 8px;
  border: 1px solid #e2e2e2ff;
}

.headerv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.header-titlev {
  font-size: 24px;
  font-weight: 700;
  color: #010e1f;
  font-family: "Visby CF";
  line-height: 32px;
}

.header-countv {
  font-size: 14px;
  color: #777777;
}

.cardv {
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 250px;
  margin: 20px;
  transition: transform 0.3s;
}

.cardv:hover {
  transform: translateY(-5px);
}

.card-imagev {
  width: 100%;
  height: 150px;
  background-color: #ffdb58;
}

.card-contentv {
  padding: 1rem;
}

.card-titlev {
  font-size: 14px;
  color: #010e1f;
  margin: 0 0 8px;
}

.card-pricev {
  font-size: 12px;
  color: #6a248f;
  margin-bottom: 1rem;
}

.card-buttonv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  font-size: 12px;
  color: #070707;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card-buttonv:hover {
  background-color: #e8e8e8;
}

.iconv {
  width: 1rem;
  height: 1rem;
}

.product-listv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px auto;
}

.voucher-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #ffffff;
  gap: 40px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 1rem rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
  height: 10%;
}

.voucher-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
}

.voucher-image {
  width: 100%;
  max-width: 541px;
  height: 308px;
  object-fit: cover;
  border-bottom: 1px solid #e6e7e8;
}

.voucher-info {
  text-align: center;
  padding: 20px 0;
}

.voucher-title {
  font-size: 24px;
  font-weight: 600;
  color: #010e1f;
  line-height: 32px;
}

.voucher-price {
  font-size: 1rem;
  font-weight: 600;
  color: #6a248f;
  line-height: 24px;
  margin-top: 4px;
}

.accordion-container {
  margin-top: 24px;
}

.accordion-section {
  border: 1px solid #e6e7e8;
  border-radius: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.accordion-content {
  padding: 1rem;
  background-color: #f8f8f8;
  color: #010e1f;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
}

.accordion-icon {
  width: 24px;
  height: 24px;
}

.pricingquantity-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-options {
  padding: 20px;
  border: 1px solid #e6e7e8;
  border-radius: 8px;
  margin-bottom: 20px;
}

.amount-header,
.quantity-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.amount-title,
.quantity-title {
  font-weight: 600;
  font-size: 18px;
  color: #010e1f;
}

.suggested-title,
.custom-title {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #55667a;
}

.suggested-amounts {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.amount-button {
  padding: 12px 1rem;
  border: 1px solid #00ae5a;
  border-radius: 8px;
  background: #fff;
  font-weight: 600;
  font-size: 14px;
  color: #00ae5a;
  transition: background 0.3s;
}

.amount-button.selected {
  background: #00ae5a;
  color: #fff;
}

.custom-amount {
  margin-top: 12px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f8f8f8;
  padding: 8px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
}

.currency-selector {
  padding: 8px;
  background: #f2f2f2;
  border-right: 1px solid #e2e2e2;
  font-size: 14px;
}

.amount-input {
  flex: 1;
  border: none;
  outline: none;
  background: #f8f8f8;
  font-size: 14px;
  color: #8a96a3;
}

.quantity-section {
  padding: 20px;
  border: 1px solid #e6e7e8;
  border-radius: 8px;
}

.quantity-selector {
  display: flex;
  align-items: center;
  gap: 4px;
}

.quantity-decrement,
.quantity-increment {
  padding: 8px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  background: #fff;
}

.quantity-input {
  text-align: center;
  width: 50px;
  border: none;
  background: #f8f8f8;
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
}

.total-amount-container {
  padding: 20px;
  border: 1px solid #e6e7e8;
  border-radius: 8px;
  text-align: center;
}

.total-amount-header {
  display: flex;
  justify-content: space-between;
}

.total-amount-header .amount {
  color: #6a248f;
  font-size: 18px;
  font-weight: 600;
}

.buy-now,
.add-to-cart {
  margin-top: 10px;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  font-weight: 600;
  border: 1px solid #00bf63;
  cursor: pointer;
}

.buy-now {
  background-color: #00ae5a;
  color: #fff;
}

.add-to-cart {
  background-color: #fff;
  color: #00ae5a;
}

.add-to-cart:hover {
  background-color: #e8f5e9;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Visby CF", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #010e1f;
  background-color: #fff;
  border-radius: 8px;
  padding: 4px 12px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.arrow-left {
  width: 24px;
  height: 24px;
  /* content: url('./assets/arrow-left.svg'); */
}

.back-buttonc {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 14px;
  color: #010e1f;
  background-color: #fff;
  border-radius: 8px;
  padding: 4px 12px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.arrow-leftc {
  width: 24px;
  height: 24px;
}

.back-buttonc:hover {
  background-color: #e8e8e8;
}

.cart-summaryc {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.cart-titlec {
  font-weight: 600;
  font-size: 24px;
  color: #010e1f;
}

.item-countc {
  font-weight: 600;
  font-size: 1rem;
  color: #8a96a3;
  justify-content: right;
  text-align: right;
  justify-items: right;
  align-items: start;
  margin-left: 1150px;
}

.cart-itemc {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 1rem;
  margin-bottom: 10px;
  border: 1px solid #e2e2e2;
}

.cart-item-detailsc {
  margin-left: 1rem;
}

.item-titlec {
  font-size: 20px;
  font-weight: 600;
  color: #010e1f;
}

.item-pricec {
  font-size: 1rem;
  font-weight: 600;
  color: #6a248f;
}

.cart-item-actionsc {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: right;
  margin-left: 800px;
}

.decrementc,
.incrementc,
.removec {
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.decrementc:hover,
.incrementc:hover {
  background-color: #f8f8f8;
}

.removec {
  color: #ff5555;
}

.removec:hover {
  background-color: #ffdddd;
}

.quantityc {
  width: 30px;
  text-align: center;
  background-color: #f8f8f8;
}

.total-sectionc {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
  padding: 10px 0;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

.total-textc {
  font-weight: 600;
  font-size: 14px;
  color: #55667a;
  justify-content: right;
}

.total-amountc {
  font-weight: 600;
  font-size: 18px;
  color: #6a248f;
}

.actionsc {
  display: grid;
  gap: 10px;
  justify-content: right;
}

.action-buttonc {
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid #00bf63;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.continuec {
  background-color: #00ae5a;
  color: #fff;
}

.continuec:hover {
  background-color: #009f50;
}

.cancelc {
  background-color: transparent;
  color: #8a96a3;
  border-color: #8a96a3;
}

.cancelc:hover {
  background-color: #e8e8e8;
}

.page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.cart-items {
  margin-bottom: 30px;
}

.preference-card {
  background-color: #fefefe;
  border-radius: 10.83px;
  padding: 12.86px 1rem;
  width: 220px;
  box-shadow: 0px 6.092px 33.847px rgba(0, 153, 80, 0.1);
}

.preference-title {
  font-family: "Visby CF", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #344860;
  margin-bottom: 24px;
}

.preference-section {
  margin-bottom: 1rem;
}

.preference-label {
  font-family: "Visby CF", sans-serif;
  font-weight: 600;
  font-size: 10px;
  color: #011a38;
  display: block;
  margin-bottom: 4px;
}

.preference-input {
  display: flex;
  align-items: center;
  padding: 6px 14px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  background-color: #fff;
  gap: 10px;
}

.flag-icon {
  width: 18px;
  height: 18px;
}

.preference-select {
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Visby CF", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #8a96a3;
}

.arrow-icon {
  width: 1rem;
  height: 1rem;
}

.find-button {
  width: 100%;
  padding: 12px;
  background-color: #00ae5a;
  color: #fff;
  font-family: "Visby CF", sans-serif;
  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
}

.preference-container {
  position: relative;
}

.preference-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  z-index: 1000;
}

.preference-content {
  padding: 20px;
}

.preference-section {
  margin-bottom: 1rem;
}

.preference-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
}

.preference-input {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px 12px;
}

.preference-select {
  flex: 1;
  border: none;
  background: none;
  margin-left: 8px;
  outline: none;
  font-size: 14px;
}

.flag-icon {
  font-size: 1rem;
}

.find-button {
  width: 100%;
  padding: 12px;
  background-color: #00ae5a;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.find-button:hover {
  background-color: #029950;
}

.banner-text-left {
  padding-left: 3rem;
}

.getsec {
  padding-bottom: 0;
}

/* MEDIA QUERIES */

/* Large Screens (1200px and above) */
@media screen and (min-width: 1200px) {
  .features-grid {
    display: flex;
    gap: 24px;
  }

  .partner-logo {
    flex-basis: calc(20% - 20px);
  }

  .reviews-wrapper {
    flex-direction: row;
  }
}

/* Medium Screens (992px to 1199px) */
@media screen and (max-width: 1199px) {
  .intro {
    font-size: 45px;
    line-height: 65px;
  }

  .features-title {
    width: 100%;
    margin-left: 0;
    font-size: 45px;
  }

  .features-grid {
    flex-wrap: wrap;
  }

  .feature-card {
    flex-basis: calc(50% - 12px);
  }

  .storyheader {
    width: 100%;
    margin-left: 0;
  }

  .reviews-wrapper {
    gap: 15px;
  }

  .review-card {
    width: 100%;
  }

  .moresec,
  .getsec,
  .purple {
    padding: 40px 30px;
    height: auto;
  }
}

/* Tablets (768px to 991px) */
@media screen and (max-width: 991px) {
  .tops {
    margin-left: 20px;
    margin-right: 20px;
    flex-wrap: wrap;
  }

  .top {
    width: 80%;
  }

  .herocountry {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
  }

  .cs {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
  }

  .features-section {
    padding: 40px 30px;
  }

  .features-grid {
    gap: 1rem;
  }

  .testimonials-container {
    padding: 40px 30px;
  }

  .reviews-wrapper {
    flex-direction: column;
  }

  #faqs {
    flex-direction: column;
    padding: 40px 30px;
  }

  .faq-content {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .how {
    margin-left: -30px;
    margin-right: -30px;
    height: auto;
  }

  .howsec {
    flex-direction: column;
    height: auto;
    padding: 30px;
  }

  .sec1,
  .sec2 {
    width: 100%;
  }

  .morepic,
  .getimg {
    width: 100%;
    height: auto;
  }
}

/* Mobile Phones (up to 767px) */
@media screen and (max-width: 767px) {
  .lipalogo {
    width: 200px;
    gap: 1rem;
  }

  .intro {
    font-size: 35px;
    line-height: 50px;
    margin-top: 20px;
  }

  .top {
    width: 100%;
  }

  .herop {
    font-size: 1rem;
    line-height: 24px;
  }

  .features-title {
    font-size: 35px;
  }

  .feature-card {
    flex-basis: 100%;
  }

  .partner-logo {
    flex-basis: calc(50% - 20px);
    padding: 12px;
  }

  .faq-tag {
    width: 80%;
  }

  .faq-title {
    font-size: 32px;
    line-height: 45px;
  }

  .faq-question-summary {
    font-size: 1rem;
  }

  .black {
    padding: 40px 20px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .footersec1,
  .footersec2 {
    flex-direction: column;
    gap: 30px;
  }

  .sec2p,
  .joinh3 {
    width: 100%;
  }

  .howp {
    width: 100%;
  }

  .shift {
    margin-left: 0;
  }

  .top2 {
    width: 50%;
    margin-left: 25%;
  }

  .titletest {
    font-size: 35px;
  }

  .getheader {
    font-size: 35px;
  }

  .getp {
    font-size: 1rem;
  }
}

/* Small Mobile Phones (up to 480px) */
@media screen and (max-width: 480px) {
  .partner-logo {
    flex-basis: 100%;
  }

  .banner-text-left {
    padding-left: 0;
  }

  #faqs {
    padding: 40px 0.5rem;
  }

  .recognition {
    padding: 20px;
    margin-top: 100px;
  }

  .rec {
    font-size: 1rem;
  }

  .review-image {
    margin-left: auto;
    margin-right: auto;
  }

  .tailoredheader {
    width: 100%;
  }

  .tailoredh2 {
    font-size: 32px;
  }

  .statsec,
  .tsec {
    padding: 40px 20px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .features-grid {
    width: 100%;
    margin: auto;
  }

  section {
    padding: 0;
  }

  .tops {
    margin-left: 0;
    margin-right: 0;
  }

  .customerrightbottom {
    margin-right: 0px;
  }

  .recognition {
    margin-top: 0;
  }

  .py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .content-section {
    margin-top: 0;
  }

  .intro {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .reclogo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .features-title {
    width: 100%;
    /* margin-left: 0;
    margin-right: 0; */
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .features-section {
    padding: 2rem 1rem;
  }

  .moresec {
    padding: 2rem 0 0 0;
  }

  .send {
    font-size: 2rem;
  }

  .sendsec {
    padding-bottom: 0;
  }

  .partners-section {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: 3rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .partners-section .sendp {
    font-family: "Visby CF", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
  }

  .partners-logos {
    gap: 15px;
    margin-top: 40px;
    width: 100%;
    margin-left: 0;
  }

  .getsec {
    padding-bottom: 0;
  }

  .metadata {
    font-size: 0.8rem;
  }

  /* .content-2 .recognition {
    margin-top: 100px;
  } */

  .shiftpartner {
    text-align: center;
  }
}

/* Large Screens (Laptops/Desktops) */
@media screen and (max-width: 1440px) {
  .world {
    width: 100%;
    height: auto;
  }

  .partner-hero-container {
    gap: 40px;
    padding: 60px 40px;
  }

  .roi-container {
    gap: 30px;
  }

  .roi-image-wrapper {
    width: 800px;
    height: 436px;
  }

  .foster-container {
    padding: 40px 120px;
  }
}

/* Medium Screens (Tablets/Small Laptops) */
@media screen and (max-width: 1024px) {
  .partner-hero-container {
    flex-direction: column;
    gap: 40px;
    margin-bottom: -80px;
  }

  .partner-hero-text {
    max-width: 100%;
  }

  .partner-hero-images {
    max-width: 100%;
  }

  .partners-grid {
    flex-wrap: wrap;
    gap: 32px;
  }

  .partner-card {
    flex: 0 0 calc(50% - 1rem);
    max-width: none;
  }

  .roi-container {
    flex-direction: column;
  }

  .roi-text-content {
    margin-left: 0;
    max-width: 100%;
  }

  .roi-button {
    width: 50%;
  }

  .power-section {
    flex-direction: column;
    gap: 40px;
  }

  .power-image {
    margin-left: 0;
    width: 100%;
    height: auto;
  }

  .power-content {
    margin: 0;
    max-width: 100%;
  }

  .grow-section {
    flex-direction: column;
    height: auto;
    padding: 40px;
  }

  .growcontent {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .growimage-container {
    max-width: 100%;
  }

  .explorecard {
    width: calc((100% - 48px) / 3);
    min-width: calc((100% - 48px) / 3);
  }
}

/* Small Screens (Tablets) */
@media screen and (max-width: 768px) {
  .contact-section {
    flex-direction: column;
    padding: 20px 30px;
  }

  .form-groupname {
    flex-direction: column;
  }

  .exh2,
  .exp {
    width: 100%;
  }

  .partners-grid {
    flex-direction: column;
  }

  .partner-card {
    flex: 0 0 100%;
  }

  .explorecard {
    width: calc((100% - 24px) / 2);
    min-width: calc((100% - 24px) / 2);
  }

  .blogs-grid {
    gap: 20px;
  }

  .blog-card {
    flex: 0 0 calc(50% - 10px);
  }

  .fostering-container {
    flex-direction: column;
    padding: 20px 30px;
  }

  .image-section {
    margin-left: 0;
    flex: none;
  }

  .content-section {
    margin-left: 0;
    margin-top: 30px;
  }

  .get-section {
    padding: 30px;
  }

  .get-title {
    font-size: 36px;
    line-height: normal;
  }
}

/* Mobile Screens */
@media screen and (max-width: 480px) {
  .partner-hero-container,
  /* .partners-section, */
  .explore-section,
  .stories-section,
  .blogs-section {
    padding: 40px 20px;
  }

  .partnerh1,
  .partners-header,
  .roi-heading,
  .growtitle,
  .power-title,
  .stories-title {
    font-size: 32px;
    line-height: normal;
  }

  .partnerp,
  .partner-description,
  .roi-description,
  .growdescription,
  .power-description {
    font-size: 14px;
  }

  .partnerbuttons {
    flex-direction: column;
    gap: 1rem;
  }

  .explorecard {
    width: 100%;
    min-width: 100%;
  }

  .blog-card {
    flex: 0 0 100%;
  }

  .roi-image-wrapper {
    width: 100%;
    height: 300px;
  }

  .roi-card {
    width: 120px;
    padding: 15px;
  }

  .foster-container {
    padding: 20px;
  }

  .image-section2 {
    height: 250px;
  }

  .explore-section {
    margin-right: 0;
    margin-left: -1rem;
    padding: 1.3rem 0;
    width: 100vw;
  }

  .explore-header h1 {
    line-height: 54.88px;
    padding-top: 2rem;
  }

  .explore-header p {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .explorecard-container {
    margin-top: 2rem;
  }

  .grow-section {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }

  .roi-section {
    margin-bottom: 0;
  }

  .power-section {
    padding: 80px 0;
  }

  .faq-questions-container {
    overflow-y: visible;
    max-height: fit-content;
  }

  .partners-content {
    padding: 1rem;
  }
}

/* Height-based media query for shorter screens */
@media screen and (max-height: 800px) {
  .grow-section {
    height: auto;
    min-height: 500px;
  }

  .partners-hero {
    height: auto;
    min-height: 350px;
  }
}

/* Large Screens (1200px and up) */
@media screen and (min-width: 1200px) {
  .navigation {
    padding-right: 120px;
    padding-left: 120px;
  }

  .search-bar {
    width: 800px;
  }

  .cart-item-actions {
    margin-left: 1000px;
  }
}

/* Medium Screens (992px to 1199px) */
@media screen and (max-width: 1199px) {
  .navigation {
    padding-right: 60px;
    padding-left: 60px;
    margin-left: 40px;
  }

  .search-bar {
    width: 500px;
  }

  .si {
    margin-left: 200px;
  }

  .cart-item-actions {
    margin-left: 400px;
  }

  .item-count {
    margin-left: 800px;
  }

  .voucher-main-container {
    gap: 20px;
    padding: 15px;
  }
}

.css-1ll44ll-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 32px;
  display: flex;
  padding-left: 2px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #333 !important;
}

/* Small Screens (768px to 991px) */
@media screen and (max-width: 991px) {
  .navigation {
    gap: 30px;
    padding-right: 30px;
    padding-left: 30px;
    margin-left: 20px;
  }

  .search-bar {
    width: 400px;
  }

  .si {
    margin-left: 150px;
  }

  .language-switcherN {
    margin-left: 20px;
  }

  .product-listv {
    justify-content: center;
  }

  .cardv {
    width: 200px;
  }

  .voucher-main-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .cart-item-actions {
    margin-left: 200px;
  }

  .item-count {
    margin-left: 500px;
  }

  .article-container {
    flex-wrap: wrap;
    gap: 20px;
  }

  .article-card {
    max-width: 280px;
  }
}

/* Tablet Screens (576px to 767px) */
@media screen and (max-width: 767px) {
  .navigation {
    flex-wrap: wrap;
    gap: 15px;
    height: auto;
    padding: 15px;
    margin-left: 0;
  }

  .filter-icon {
    margin-left: 0;
  }

  .search-bar {
    width: 100%;
    order: 2;
  }

  .si {
    margin-left: 50px;
  }

  .language-switcherN {
    margin-left: 10px;
  }

  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item-actions {
    margin-left: 0;
    margin-top: 15px;
    width: 100%;
    justify-content: space-between;
  }

  .item-count {
    margin-left: 200px;
  }

  .actions {
    width: 100%;
  }

  .preference-card {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .article-card {
    max-width: 100%;
  }
}

/* Mobile Screens (575px and below) */
@media screen and (max-width: 575px) {
  .navigation {
    padding: 10px;
  }

  .search-bar {
    padding: 8px;
  }

  .category-dropdown,
  .search-input {
    font-size: 12px;
  }

  .language-switcherN {
    padding: 8px;
    margin-left: 0;
  }

  .headerv {
    padding: 10px;
  }

  .header-titlev {
    font-size: 20px;
  }

  .cardv {
    width: 100%;
    margin: 10px 0;
  }

  .voucher-info {
    padding: 15px;
  }

  .voucher-title {
    font-size: 20px;
  }

  .accordion-header {
    padding: 12px;
  }

  .item-count {
    margin-left: 100px;
  }

  .cart-summary {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .total-section {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .action-button {
    width: 100%;
  }

  .related-section {
    padding: 20px;
  }

  .article-container {
    gap: 15px;
  }
}
